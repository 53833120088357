import React, { useEffect } from 'react'
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux'
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import { deleteproduct, fetchproduct, updateproduct,fetchsubAdvertisement } from '../../store/productSlice';


const NewProducts = () => {

    const dispatch = useDispatch()
    const {data: product, status} = useSelector((state) => state?.product)

    useEffect(() => {
        dispatch(fetchsubAdvertisement())
    }, [dispatch])

    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable(
                {
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true,
                    // dom: 'Bfrtip',
                    // buttons: ['copy', 'csv', 'print']
                }
            );
        },
            1000
        );
    });


    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deleteproduct(id))
            }

        })
    }


    const approveSeller = (id,status) => {
        Modal.confirm({
            title: 'Are you sure you want to approve?',
            onOk: () => {

                const param=new FormData();
                param.append('status', status);
                

                dispatch(updateproduct(id,param))
            }

        })
    }


    const approveFeatured = (id,featured) => {
        Modal.confirm({
            title: 'Are you sure you want to set as featured?',
            onOk: () => {

                const param=new FormData();
                param.append('featured', featured);
                

                dispatch(updateproduct(id,param))
            }

        })
    }


    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px !important' }}>
            <div className="content container-fluid mt-5">
                <div className="mb-3">
                    <h2 className="h1 mb-0 text-capitalize d-flex gap-2">
                        <img
                            src="https://6valley.6amtech.com/public/assets/back-end/img/inhouse-product-list.png"
                            alt=""
                        />
                      Sub Advertisement
                        <span className="badge badge-soft-dark radius-50 fz-14 ml-1">2</span>
                    </h2>

                </div>
                <div className="row mt-20">
                    <div className="col-md-12">
                        <div className="card p-3">
                            <div className="card-body p-0">
                                <div className="table-responsive">
                                    <table
                                        id="allOrder"
                                        style={{ textAlign: "left" }}
                                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                    >
                                        <thead className="thead-light thead-50 text-capitalize">
                                        <tr>
                                            <th>SL</th>
                                            <th>Name</th>
                                            <th>Service name</th>
                                            <th>Image</th>
                                            <th> price</th>
                                            <th>Discount</th>
                                            <th>Edit /  Delete</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {product?.length > 0 ? product?.map((item, index) => {
                                           
                                           return (
                                               <>
                                                   <tr>
                                                       <th scope="row">{index + 1}</th>
                                                           <td>{item?.addTitle}</td>
                                                           <td>{item?.serviceName}</td>
                                                       <td>
                                                           
                                                               <img
                                                                   src={item?.image}
                                                                   className="avatar border"
                                                                   alt=""
                                                               />
                                                           
                                                          
                                                       </td>
                                                       <td>{item?.price}</td>
                                                       <td>{item?.discountPercentage}</td>
                                                       <td>
                                                           <div className="d-flex gap-3">

                                                           {/* <button
                                                          className=" btn btn-outline-success square-btn btn-sm mr-1"
                                                 onClick={()=> navigate('/UpdateProduct/'+item?._id)}
                                                                             >
                                                            <i className="bi bi-pencil" />
                                                      </button> */}
                                                               <a
                                                                   className="btn btn-outline-danger btn-sm square-btn"
                                                                   title="Delete"
                                                                   onClick={()=>onDelete(item?._id)}
                                                               >
                                                               </a>
                                                           </div>
                                                       </td>
                                                   </tr>
                                               </>
                                           )
                                       
                                   }) : null}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default NewProducts
