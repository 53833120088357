import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, message } from 'antd';
import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import Loader from '../Loader';
import { fetchsubCategory, createsubCategory, deletesubCategory, updatesubCategoryStatus } from '../../store/subCategorySlice';
import { fetchcategory,getByIdcategory } from '../../store/categorySlice';
import axios from 'axios';
const SubCategory = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data_sub } = useSelector((state) => state);
    const { data: subCategory } = useSelector((state) => state.subCategory);
    const { data: category } = useSelector((state) => state.category);
    
    const [categoryid, setCategoryid] = useState('');
    const [sub_Category, setSub_Category] = useState('');
    const [loading, setLoading] = useState(false);
    const [checkedStates, setCheckedStates] = useState([]);
    const [uploadStatus, setUploadStatus] = useState('');
    const [file, setFile] = useState(null);

    useEffect(() => {
        dispatch(fetchcategory());
        dispatch(fetchsubCategory());
        
    }, [dispatch]);

    useEffect(() => {
        // Initialize checkedStates based on subCategory status
        if (subCategory && subCategory.length > 0) {
            const initialCheckedStates = subCategory.map((item) => item.status);
            setCheckedStates(initialCheckedStates);
        }
    }, [subCategory]);

    // Initialize DataTable
    useEffect(() => {
        $(document).ready(function () {
            setTimeout(function () {
                $('#allOrder').DataTable({
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true
                });
            }, 1000);
        });
    }, []);
    const handleUpload = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        try {
            const response = await axios.post('http://5.161.110.71:5000/common/auth/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadStatus(`Upload is ${percentCompleted}% done.`);
                }
            });

            setFile(response.data.data);
            message.success('Image uploaded successfully!');
        } catch (error) {
            console.error('Upload error:', error);
            message.error('Failed to upload image.');
        }
    };

    const toggleSwitch = async (index, subcategoryId, currentStatus) => {
        const newStatus = !checkedStates[index]; // Toggle the current state
        console.log(subcategoryId, newStatus, currentStatus);
        try {
            // Update UI optimistically
            setCheckedStates((prevStates) => {
                const newStates = [...prevStates];
                newStates[index] = newStatus;
                return newStates;
            });

            // Update backend via Redux action
            await dispatch(updatesubCategoryStatus({ id: subcategoryId, status: newStatus }));

            // Handle success (if needed)
            console.log('Status updated successfully');
        } catch (error) {
            // Handle error (rollback UI changes if needed)
            console.error('Error updating status:', error);

            // Revert UI changes on failure
            setCheckedStates((prevStates) => {
                const newStates = [...prevStates];
                newStates[index] = currentStatus; // Revert to previous status
                return newStates;
            });

            // Optionally show error message
            message.error('Failed to update category status.');
        }
    };

    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deletesubCategory(id));
            }
        });
    };

    const handleSubCategory = (e) => {
        e.preventDefault();

        if (sub_Category.trim() === '') {
            message.error('Must enter subcategory name');
            return;
        }
        if (!categoryid) {
            message.error('Must select a category');
            return;
        }

        dispatch(createsubCategory({
            name: sub_Category,
            categoryId: categoryid,
            image:file
        }));
    };

    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px !important' }}>
            {loading ? <Loader /> : null}
            <div className="content container-fluid mt-5">
                <div>
                    <h2 className="h1 mb-0 d-flex gap-10">
                        <img
                            src="https://6valley.6amtech.com/public/assets/back-end/img/brand-setup.png"
                            alt=""
                        />
                        Sub Category Setup
                    </h2>

                    <form>
                        <div className="card mt-2 p-4">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group  lang_form" id="en-form">
                                        <label className="title-color" htmlFor="exampleFormControlInput1">
                                            Sub category Name<span className="text-danger">*</span>
                                            (EN)
                                        </label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="form-control"
                                            placeholder="New Sub Category"
                                            required=""
                                            onChange={(e) => setSub_Category(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label className="title-color" htmlFor="exampleFormControlSelect1">
                                            Main Category
                                            <span className="text-danger">*</span>
                                        </label>
                                        <select
                                            name="categoryId"
                                            id="categoryId"
                                            className="form-control"
                                            required=""
                                            onChange={(e) => setCategoryid(e.target.value)}
                                        >
                                            <option value="" selected disabled>
                                                Select Category
                                            </option>
                                            {category?.length > 0 && category.map((cat) => (
                                                <option key={cat._id} value={cat._id}>{cat.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label className="title-color">Sub Category Image</label>
                                <div className="custom-file text-left">
                                    {file ? <img style={{ display: 'block' }} width={100} src={file} alt='' /> : (
                                        <img
                                            className="upload-img-view"
                                            id="viewer"
                                            src="https://6valley.6amtech.com/public/assets/back-end/img/900x400/img1.jpg"
                                            alt="image"
                                        />
                                    )}
                                </div>

                                </div>
                                   

                                <div className="from_part_2" style={{ marginTop: '100px' }}>
                                <label className="custom-file-label" htmlFor="customFileEg1">
                                            Choose File
                                        </label>
                                    <div className="custom-file text-left">
                                        <input
                                            type="file"
                                            name="image"
                                            id="image"
                                            className="custom-file-input"
                                            accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                            required=""
                                            onChange={(e) => handleUpload(e)}
                                        />
                                       
                                    </div>
                                </div>

                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                <button type="reset" id="reset" className="btn btn-secondary">
                                    Reset
                                </button>
                                <button onClick={(e) => handleSubCategory(e)} type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>

                    <div className="row mt-3" id="cate-table">
                        <div className="col-md-12">
                            <div className="card p-3">
                                <div className="px-3 py-4">
                                    <div className="row align-items-center">
                                        <div className="col-sm-4 col-md-6 col-lg-8 mb-2 mb-sm-0">
                                            <h5 className="text-capitalize d-flex gap-1">
                                                Sub Category list
                                                <span className="badge badge-soft-dark radius-50 ">{subCategory?.length}</span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table
                                        id='allOrder'
                                        style={{ textAlign: "left" }}
                                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                    >
                                        <thead className="thead-light thead-50 text-capitalize">
                                            <tr>
                                                <th>SL</th>
                                                <th>Name</th>
                                                <th>Category</th>
                                                <th>Date</th>

                                                <th>Status</th>
                                                <th>Logo</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subCategory?.length > 0 && subCategory.map((sub, index) => (
                                                <tr key={sub._id}>
                                                    <td>{index + 1}</td>
                                                    <td>{sub.name}</td>
                                                    <td>{sub?.categoryId?.name}</td>
                                                    <td>{sub.createdAt}</td>
                                                    <td className="">
                                                        <div style={{
                                                            position: 'relative',
                                                            display: 'inline-block',
                                                            width: '40px',
                                                            height: '20px',
                                                        }}>
                                                            <input
                                                                type="checkbox"
                                                                checked={checkedStates[index]}
                                                                onChange={() => toggleSwitch(index, sub._id, sub.status)}
                                                                style={{
                                                                    opacity: 0,
                                                                    width: 0,
                                                                    height: 0,
                                                                }}
                                                            />
                                                            <div
                                                                onClick={() => toggleSwitch(index, sub._id, sub.status)}
                                                                style={{
                                                                    position: 'absolute',
                                                                    cursor: 'pointer',
                                                                    top: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    backgroundColor: checkedStates[index] ? '#2196F3' : '#ccc',
                                                                    transition: '.4s',
                                                                    borderRadius: '20px',
                                                                }}
                                                            >
                                                                <div style={{
                                                                    position: 'absolute',
                                                                    content: '""',
                                                                    height: '16px',
                                                                    width: '16px',
                                                                    left: checkedStates[index] ? '22px' : '2px',
                                                                    bottom: '2px',
                                                                    backgroundColor: 'white',
                                                                    transition: '.4s',
                                                                    borderRadius: '50%',
                                                                }}></div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                    <img
                                                        className="rounded"
                                                        width={64}
                                                        src={sub.image}
                                                        alt={sub.name}
                                                    />
                                                </td>
                                                    <td>
                                                        <div className="d-flex justify-content-center gap-2">
                                                            <button
                                                                className=" btn btn-outline-success square-btn btn-sm mr-1"
                                                                onClick={() => navigate(`/UpdateSubCate/${sub._id}`)}
                                                            >
                                                                <i className="bi bi-pencil" />
                                                            </button>
                                                            <button
                                                                onClick={() => onDelete(sub._id)}
                                                                className=" btn btn-outline-danger square-btn btn-sm mr-1"
                                                                title="Delete"
                                                            >
                                                                <i className="bi bi-trash" />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubCategory;
