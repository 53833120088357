import React, { useEffect, useState } from 'react';
import { Modal, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { deleteproduct, fetchproduct, updateproduct, updateproductStatus } from '../../store/productSlice';
import $ from 'jquery';
import 'jquery/dist/jquery.min.js';
import { AiFillDelete } from "react-icons/ai";
import { BsPencilFill } from "react-icons/bs";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link } from 'react-router-dom';

const Products = () => {
    const dispatch = useDispatch();
    const { data: product, status } = useSelector(state => state.product);
    const [checkedStates, setCheckedStates] = useState([]);
    const [filteredProduct, setFilteredProduct] = useState([]);

    useEffect(() => {
        dispatch(fetchproduct());
    }, []);

    useEffect(() => {
        const filtered = product.filter(item => item.status === 'approved');
        setFilteredProduct(filtered);

        // Initialize checkedStates based on filtered product length
        const initialCheckedStates = filtered.map(item => item.status === 'approved');
        setCheckedStates(initialCheckedStates);
    }, [product]);

    useEffect(() => {
        $(document).ready(function () {
            setTimeout(function () {
                $('#allOrder').DataTable({
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    destroy: true,
                });
            }, 1000);
        });
    }, []);

    const toggleSwitch = async (index, id, currentStatus) => {
        const newStatus = !checkedStates[index]; // Toggle the current state

        try {
            // Optimistically update UI
            setCheckedStates(prevStates => {
                const newStates = [...prevStates];
                newStates[index] = newStatus;
                return newStates;
            });

            // Update backend via Redux action
            await dispatch(updateproductStatus({ id }));

            // Handle success
            console.log('Status updated successfully');
        } catch (error) {
            // Handle error
            console.error('Error updating status:', error);

            // Revert UI changes on failure
            setCheckedStates(prevStates => {
                const newStates = [...prevStates];
                newStates[index] = currentStatus; // Revert to previous status
                return newStates;
            });

            // Optionally show error message
            message.error('Failed to update product status.');
        }
    };

    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deleteproduct(id));
            }
        });
    };

    return (
        <div className='mt-5 toppp'>
            <div className="content container-fluid mt-5">
                <div className="mb-3">
                    <h2 className="h1 mb-0 text-capitalize d-flex gap-2">
                        <img
                            src="https://6valley.6amtech.com/public/assets/back-end/img/inhouse-product-list.png"
                            alt=""
                        />
                        Advertisement
                        <span className="badge badge-soft-dark radius-50 fz-14 ml-1">16</span>
                    </h2>
                </div>
                <div className="row mt-20">
                    <div className="card p-3">
                        <div className="py-4">
                            <div className="row align-items-center">
                                <div className="d-flex justify-content-end">
                                    {/* Add any additional buttons or links here */}
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table
                                    id="allOrder"
                                    style={{ textAlign: "left" }}
                                    className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                >
                                    <thead className="thead-light thead-50 text-capitalize">
                                        <tr>
                                            <th>SL</th>
                                            <th>Name</th>
                                            <th>Service name</th>
                                            <th>Image</th>
                                            <th>Price</th>
                                            <th>Discount</th>
                                            <th>Status</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredProduct?.map((item, index) => (
                                            <tr key={item._id}>
                                                <td>{index + 1}</td>
                                                <td>{item.addTitle}</td>
                                                <td>{item.serviceName}</td>
                                                <td>
                                                    <img
                                                        src={item.image}
                                                        className="avatar border"
                                                        alt=""
                                                    />
                                                </td>
                                                <td>{item.price}</td>
                                                <td>{item.discountPercentage}</td>
                                                <td className="text-center">
                                                    <div
                                                        style={{
                                                            position: 'relative',
                                                            display: 'inline-block',
                                                            width: '40px',
                                                            height: '20px',
                                                        }}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={checkedStates[index]}
                                                            onChange={() => toggleSwitch(index, item._id, item.status)}
                                                            style={{
                                                                opacity: 0,
                                                                width: 0,
                                                                height: 0,
                                                            }}
                                                        />
                                                        <div
                                                            onClick={() => toggleSwitch(index, item._id, item.status)}
                                                            style={{
                                                                position: 'absolute',
                                                                cursor: 'pointer',
                                                                top: 0,
                                                                left: 0,
                                                                right: 0,
                                                                bottom: 0,
                                                                backgroundColor: checkedStates[index] ? '#2196F3' : '#ccc',
                                                                transition: '.4s',
                                                                borderRadius: '20px',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    content: '""',
                                                                    height: '16px',
                                                                    width: '16px',
                                                                    left: checkedStates[index] ? '22px' : '2px',
                                                                    bottom: '2px',
                                                                    backgroundColor: 'white',
                                                                    transition: '.4s',
                                                                    borderRadius: '50%',
                                                                }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                <div className="d-flex gap-3">
                                                            <a
                                                                className="btn btn-outline-danger btn-sm square-btn"
                                                                title="Delete"
                                                                onClick={() => onDelete(item._id)}
                                                            >
                                                                <i className="bi bi-trash" />
                                                            </a>
                                                        </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Products;
