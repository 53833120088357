import React, { useEffect } from 'react'
import 'jquery/dist/jquery.min.js';
import {AiFillDelete} from "react-icons/ai";
import { BsPencilFill } from "react-icons/bs";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux'
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import { deleteproduct, fetchBoughtSubscription, fetchBoughtVouchers, fetchproduct, updateproduct } from '../../store/productSlice';
import { useHistory,useNavigate } from 'react-router-dom';
const Bought_subscription = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { data: product } = useSelector((state) => state?.product)

    useEffect(() => {
        dispatch(fetchBoughtSubscription())
    }, [])

    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable(
                {
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true,
                    // dom: 'Bfrtip',
                    // buttons: ['copy', 'csv', 'print']
                }
            );
        },
            1000
        );
    });

  

    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deleteproduct(id))
            }

        })
    }


    const approveSeller = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to approve?',
            onOk: () => {

                const param = new FormData();
                param.append('status', true);


                dispatch(updateproduct(id, param))
            }

        })
    }

    return (
        <div className='mt-5 toppp' >
            <div className="content container-fluid mt-5">
                <div className="mb-3">
                    <h2 className="h1 mb-0 text-capitalize d-flex gap-2">
                        <img
                            src="https://6valley.6amtech.com/public/assets/back-end/img/inhouse-product-list.png"
                            alt=""
                        />
                       Subscription
                        <span className="badge badge-soft-dark radius-50 fz-14 ml-1">16</span>
                    </h2>


                </div>
                <div className="row mt-20">

                    <div className="card p-3" >
                        <div className="py-4">
                            <div className="row align-items-center">

                                <div className="d-flex justify-content-end">

                                    {/* <Link
                                            className="btn btn-info"
                                        >
                                            <span className="text">Limited Sotcks</span>
                                        </Link> */}
                                    {/* <Link
                                        to="/addProducts"
                                        className="btn btn-primary"
                                    >
                                        <i className="tio-add" />
                                        <span className="text">Add New Product</span>
                                    </Link> */}
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="table-responsive">
                                <table
                                    id="allOrder"
                                    style={{ textAlign: "left" }}
                                    className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                >
                                    <thead className="thead-light thead-50 text-capitalize">
                                        <tr>
                                            <th>SL</th>
                                            <th>Subscription name</th>
                                            <th>Bussiness name</th>
                                            <th>Bussiness Logo</th>
                                            <th>Price</th>
                                            <th>End date</th>
                                            <th>Transaction Id</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {product?.length > 0 ? product?.map((item, index) => {
                                             const endDate = new Date(item?.endDate);
                                             const formattedDate = !isNaN(endDate.getTime())
                                                 ? endDate.toISOString().split('T')[0]
                                                 : 'Invalid Date';
                                           
                                                return (
                                                    <>
                                                        <tr>
                                                            <th scope="row">{index + 1}</th>
                                                                <td>{item?.addId?.name}</td>
                                                                <td>{item?.businessId?.companyName}</td>
                                                            <td>
                                                                
                                                            <img
                                                                        src={item?.businessId?.companyLogo}
                                                                        className="avatar border"
                                                                        alt=""
                                                        />
                                                                
                                                               
                                                            </td>
                                                            <td>{item?.paymentAmount}</td>
                                                            <td>{formattedDate}</td>
                                                            <td>
                                                            {item?.transactionId}
                                                                {/* <div className="d-flex gap-3">

                                                
                                                                    <a
                                                                        className="btn btn-outline-danger btn-sm square-btn"
                                                                        title="Delete"
                                                                        onClick={()=>onDelete(item?._id)}
                                                                    >
                                             <i className="bi bi-trash" />   
                                                                                                               </a>
                                                                </div> */}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            
                                        }) : null}


                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default Bought_subscription;
