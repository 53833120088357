import React, { useEffect, useState } from 'react';
import 'jquery/dist/jquery.min.js';
// Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, message } from 'antd';
import { createSubscription, deletesubscription, fetchSubscription} from '../../store/allOrderSlice';
import { getSingByIdflash } from '../../store/flashDealProductSlice';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const FlashDeal = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const  {data}  = useSelector((state) => state?.allOrder
    );
    // const { data } = useSelector((state) => state);
     console.log(data)
    useEffect(() => {
        dispatch(fetchSubscription());
    }, []);
    const [file, setFile] = useState(null);

    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable({
                pagingType: 'full_numbers',
                pageLength: 25,
                processing: true,
                bDestroy: true,
            });
        }, 1000);
    });

    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deletesubscription(id));
            }
        });
    };

    const handleCategory = (values) => {
        const status = document.getElementById('status').checked;
        console.log(status)
       
     if (values.shortDescription.value === '') {
            message.error('Must enter Description');
        }
        else if(values.duration.value ===''){
            message.error('Must enter duration');

        }
        else if (values.cost.value ===''){
            message.error('Must enter cost');

        }
        else if(values.allowedAds.value === ''){
            message.error('Must enter usage limit');

        }
        else {
           
            dispatch(createSubscription({name:values.name.value,duration:Number(values.duration.value),cost:Number(values.cost.value),shortDescription:values.shortDescription.value,allowedAds:values.allowedAds.value,status:status}));
        }
    };

  

 

    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px !important' }}>
            <div className="content container-fluid mt-5">
                <div>
                    <h2 className="h1 mb-0 text-capitalize d-flex gap-2">
                        <img
                            width={20}
                            src="https://6valley.6amtech.com/public/assets/back-end/img/flash_deal.png"
                            alt=""
                        />
Subscription
                    </h2>
                    <form onSubmit={(e) => { e.preventDefault(); handleCategory(e.target) }}>
                        <div className="card mt-2 p-4">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className=" lang_form" id="en-form">
                                        <div className="form-group">
                                            <label
                                                htmlFor="name"
                                                className="title-color font-weight-medium text-capitalize"
                                            >
                                            Name
                                            </label>
                                            <input
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                id="name"
                                                placeholder="Ex : LUX"
                                                required=""
                                                
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label
                                            htmlFor="name"
                                            className="title-color font-weight-medium text-capitalize"
                                        >
                                    Duration
                                 </label>
                                        <input
                                            type="number"
                                            id="duration"
                                            name="duration"
                                        
                                            className='form-control'
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label
                                            htmlFor="cost"
                                            className="title-color font-weight-medium text-capitalize"
                                        >
                                    Cost
                                 </label>
                                        <input
                                            type="number"
                                            id="cost"
                                            name="cost"
                                        
                                            className='form-control'
                                        />
                                    </div>
                                    
                                    
                                    <div className="form-group">
                                        <label
                                            htmlFor="name"
                                            className="title-color font-weight-medium text-capitalize"
                                        >
                                            Description
                                        </label>
                                        <textarea
                                            type="text"
                                            name="shortDescription"
                                            className="form-control"
                                            id="shortDescription"
                                            placeholder="Enter description"
                                            required=""
                                            maxLength='500'
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label
                                            htmlFor="name"
                                            className="title-color font-weight-medium text-capitalize"
                                        >
                                    Allowed ads
                                 </label>
                                        <input
                                            type="number"
                                            id="allowedAds"
                                            name="allowedAds"
                                        
                                            className='form-control'
                                        />
                                    </div>
                                    <div className="" >
                                    <label>
                            Status:
                            </label>
                            <input id='status' style={{width:'16px' ,height:'16px',margin:'5px'}} type="checkbox" name="status"  />
                       
                                      
                                    </div>
                                </div>
                                {/* <div className="col-lg-6">
                                    <div className="form-group">
                                        <label
                                            htmlFor="name"
                                            className="title-color font-weight-medium text-capitalize"
                                        >
                                            Upload image
                                        </label>
                                        <div className="custom-file text-left">
                                            <input
                                                type="file"
                                                name="image"
                                                id="image"
                                                className="custom-file-input"
                                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                                onChange={(e)=>handleUpload(e)}
                                            />
                                            <label
                                                className="custom-file-label text-capitalize"
                                                htmlFor="customFileUpload"
                                            >
                                                Choose file
                                            </label>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                <button type="reset" id="reset" className="btn btn-secondary">
                                    Reset
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                    {/* Flash deal table */}
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <div className="card p-3">
                                <div className="px-3 py-4">
                                    <div className="row align-items-center">
                                        <div className="col-sm-4 col-md-6 col-lg-8 mb-2 mb-sm-0">
                                            <h5 className="mb-0 text-capitalize d-flex gap-2">
                                               Subscriptions
                                                <span className="badge badge-soft-dark radius-50 fz-12">4</span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table
                                        id='allOrder'
                                        style={{ textAlign: "left" }}
                                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                                    >
                                        <thead className="thead-light thead-50 text-capitalize">
                                            <tr>
                                                <th>SL</th>
                                                <th>Name</th>
                                                <th>Cost</th>
                                                <th>Duration</th>
                                                <th>AllowedAds</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.length > 0 ? data && data?.map((i, index) => {
                                                return (<tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <span className="font-weight-semibold">{i?.name}</span>
                                                    </td>
                                                    <td>
                                                        <span className="">{i?.cost} </span>
                                                    </td>
                                                    
                                                    <td>
                                                       
                                                    <span className="">{i?.duration} </span>

                                                    </td>
                                                    <td>{i?.allowedAds}</td>
                                                    <td className="text-center">
                                                        <div className="d-flex align-items-center justify-content-center gap-3">
                                                            <button
                                                                title="Delete"
                                                                className="btn btn-outline-danger btn-sm"
                                                                onClick={() => { onDelete(i?._id) }}
                                                            >
                                                                <i className="bi bi-trash" />
                                                            </button>
                                                            <a
                                                                className="h-30 d-flex gap-2 align-items-center btn btn-soft-info btn-sm border-info"
                                                                onClick={() => navigate(`/updatesubscription/${i?._id}`)}
                                                            >
                                                            <i className="bi bi-pencil" />

                                                              
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>)
                                            }) : ''}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-responsive mt-4">
                                    <div className="px-4 d-flex justify-content-lg-end"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FlashDeal;
