import React, { useState } from "react";
import { NavLink } from "react-router-dom";

function Sidebar({ width, display }) {
    const [activeCollapse, setActiveCollapse] = useState(null);

    const NavLinkStyle = ({ isActive }) => {
        return {
            backgroundColor: isActive ? 'white' : '',
            borderLeft: isActive ? '5px solid #0c63e4' : 'none'
        }
    };

    const handleCollapse = (collapseId) => {
        if (activeCollapse === collapseId) {
            setActiveCollapse(null); // Close the active dropdown if clicked again
        } else {
            setActiveCollapse(collapseId); // Open the clicked dropdown
        }
    };

    const handleNavLinkClick = (collapseId) => {
        if (activeCollapse === collapseId) {
            setActiveCollapse(null); // Close the clicked dropdown if it's already active
        }
    };

    return (
        <aside className="sidebar-wrapper" data-simplebar="true" style={{ width: width, display: display }}>
            <div className="sidebar-header">
                <div>
                    <img
                        src={require("../download.png")}
                        className="logo-icon"
                        alt="logo icon"
                    />
                </div>
                <div>
                    <h4 className="logo-text">Ashh Karo</h4>
                </div>
            </div>
            <ul className="metismenu">
                <li>
                    <NavLink
                        style={NavLinkStyle({ isActive: activeCollapse === 'collapseDashboard' })}
                        to="/"
                        className={`accordion-button collapsed bg-light ${activeCollapse === 'collapseDashboard' ? 'active' : ''}`}
                        onClick={() => handleNavLinkClick('collapseDashboard')}
                    >
                        <div className="parent-icon">
                            <i className="bi bi-house-fill" />
                        </div>
                        <div className="menu-title">Dashboard</div>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        style={NavLinkStyle({ isActive: activeCollapse === 'collapseFour' })}
                        to="javascript:"
                        className={`accordion-button collapsed bg-light ${activeCollapse === 'collapseFour' ? 'active' : ''}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                        onClick={() => handleCollapse('collapseFour')}
                    >
                        <div className="parent-icon">
                            <i className="bi bi-border-width" />
                        </div>
                        <div className="menu-title">Advertisement</div>
                    </NavLink>
                    <div
                        id="collapseFour"
                        className={`accordion-collapse collapse ${activeCollapse === 'collapseFour' ? 'show' : ''}`}
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body p-0">
                            <div className="sub-menu">
                                <ul className="p-0">
                                    <li>
                                        <NavLink style={NavLinkStyle} to='/advertisement'>
                                            <i className="bi bi-check-all px-3" /> &nbsp; Adds 
                                        </NavLink>
                                    </li>
                                    {/* <li>
                                        <NavLink style={NavLinkStyle} to='/sub_advertisement'>
                                            <i className="bi bi-arrow-clockwise px-3" /> &nbsp; Sub Adds
                                        </NavLink>
                                    </li> */}
                                    <li>
                                        <NavLink style={NavLinkStyle} to='/advertisement_approval'>
                                            &nbsp; Approvals
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <NavLink
                        style={NavLinkStyle({ isActive: activeCollapse === 'collapseNine' })}
                        to="javascript:"
                        className={`accordion-button collapsed bg-light ${activeCollapse === 'collapseNine' ? 'active' : ''}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                        onClick={() => handleCollapse('collapseNine')}
                    >
                        <div className="parent-icon">
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAJ5JREFUSEu9ldENwjAMBS8TdARgk44Ck8EojAIbsEGRJSwlESgWzmt/09zZL4lcEH9FzGdXwQpcgWOyqwdwAe7GqTuwhUMS7tuNdeoF22c1G1vDqWG7C1wYTc2LDXcgF0Qr7/8LdyAXyCOSC+QRyQW/Ihq99PAtkgvkEU0XvIDlX2q37+lzpT4wGzi3CTPB4OdvA2dS8S1mdOXSUrngDbnQLBkqK9hLAAAAAElFTkSuQmCC" />
                        </div>
                        <div className="menu-title">Business</div>
                    </NavLink>
                    <div
                        id="collapseNine"
                        className={`accordion-collapse collapse ${activeCollapse === 'collapseNine' ? 'show' : ''}`}
                        aria-labelledby="headingNine"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body p-0">
                            <div className="sub-menu">
                                <ul className="p-0">
                                    <li>
                                        <NavLink to='/Bussiness' style={NavLinkStyle}>
                                            &nbsp; Business
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink style={NavLinkStyle} to='/bussiness_approvals'>
                                            &nbsp; Approvals
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <NavLink
                        style={NavLinkStyle({ isActive: activeCollapse === 'collapse13' })}
                        to="javascript:"
                        className={`accordion-button collapsed bg-light ${activeCollapse === 'collapse13' ? 'active' : ''}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse13"
                        aria-expanded="false"
                        aria-controls="collapse13"
                        onClick={() => handleCollapse('collapse13')}
                    >
                        <div className="parent-icon">
                            <i className="bi bi-grid-fill" />
                        </div>
                        <div className="menu-title">Category</div>
                    </NavLink>
                    <div
                        id="collapse13"
                        className={`accordion-collapse collapse ${activeCollapse === 'collapse13' ? 'show' : ''}`}
                        aria-labelledby="heading13"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body p-0">
                            <div className="sub-menu mm-active">
                                <ul className="p-0 mm-show">
                                    <li className="mm-active">
                                        <NavLink style={NavLinkStyle} to="/category">
                                            <i className="bi bi-grid-fill px-3" />&nbsp; Categories
                                        </NavLink>
                                    </li>
                                    <li className="mm-active">
                                        <NavLink style={NavLinkStyle} to="/subCategory">
                                            <i className="bi bi-grid-fill px-3" /> &nbsp; Sub category
                                        </NavLink>
                                    </li>
                                    <li className="mm-active">
                                        <NavLink style={NavLinkStyle} to="/subSubCategory">
                                            <i className="bi bi-grid-fill px-3" /> &nbsp; Sub sub category
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <NavLink
                        style={NavLinkStyle({ isActive: activeCollapse === 'collapseCoupons' })}
                        className={`accordion-button collapsed bg-light ${activeCollapse === 'collapseCoupons' ? 'active' : ''}`}
                        to="javascript:"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseCoupons"
                        aria-expanded="false"
                        aria-controls="collapseCoupons"
                        onClick={() => handleCollapse('collapseCoupons')}
                    >
                        <div className="parent-icon">
                            <i className="bi bi-award-fill" />
                        </div>
                        <div className="menu-title">Coupons</div>
                    </NavLink>
                    <div
                        id="collapseCoupons"
                        className={`accordion-collapse collapse ${activeCollapse === 'collapseCoupons' ? 'show' : ''}`}
                        aria-labelledby="headingCoupons"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body p-0">
                            <div className="sub-menu">
                                <ul className="p-0">
                                    <li>
                                        <NavLink style={NavLinkStyle} to='/coupons'>
                                            &nbsp;  Add Coupons
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink style={NavLinkStyle} to='/bought_coupons'>
                                            &nbsp;  Bought  Coupons 
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <NavLink
                        style={NavLinkStyle({ isActive: activeCollapse === 'collapseShipping' })}
                        to="javascript:"
                        className={`accordion-button collapsed bg-light ${activeCollapse === 'collapseShipping' ? 'active' : ''}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseShipping"
                        aria-expanded="false"
                        aria-controls="collapseShipping"
                        onClick={() => handleCollapse('collapseShipping')}
                    >
                        <div className="parent-icon">
                            <i className="bi bi-award-fill" />
                        </div>
                        <div className="menu-title">Ads Featured</div>
                    </NavLink>
                    <div
                        id="collapseShipping"
                        className={`accordion-collapse collapse ${activeCollapse === 'collapseShipping' ? 'show' : ''}`}
                        aria-labelledby="headingShipping"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body p-0">
                            <div className="sub-menu">
                                <ul className="p-0">
                                <li>
                                        <NavLink style={NavLinkStyle} to='/subscription'>
                                            &nbsp; Subscription
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink style={NavLinkStyle} to='/bought_subscription'>
                                            &nbsp;  Bought  Subscription 
                                        </NavLink>
                                    </li>  
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <NavLink
                        style={NavLinkStyle({ isActive: activeCollapse === 'collapseLogout' })}
                        to="/"
                        className={`accordion-button collapsed bg-light ${activeCollapse === 'collapseLogout' ? 'active' : ''}`}
                        onClick={() => handleNavLinkClick('collapseLogout')}
                    >
                        <div className="parent-icon">
                            <i className="bi bi-box-arrow-left" />
                        </div>
                        <div className="menu-title">Logout</div>
                    </NavLink>
                </li>
                <li style={{ display: "flex" }}>
                    <a href="https://wolftechs.pk/">Create by <span style={{ fontWeight: 'bold', marginLeft: '4px' }}> Wolf tech</span></a>
                </li>
            </ul>
        </aside>
    );
}

export default Sidebar;
