import logo from './logo.svg';
import './App.css';
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import OutRoutes from './pages/OutRoutes';
import Dashboard from './pages/Dashboard';

import NewProducts from './pages/sellerProducts/NewProducts';
import ApprovedProducts from './pages/sellerProducts/ApprovedProducts';
import DeniedProducts from './pages/sellerProducts/DeniedProducts';
import Category from './pages/category/Category';
import SubCategory from './pages/category/SubCategory';
import SubSubCategory from './pages/category/SubSubCategory';
import Products from './pages/inhouseProducts/Products';
import Banners from './pages/banners/Banners';
import FlashDeal from './pages/deals/FlashDeal';
import DealDay from './pages/deals/DealDay';
import FeaturedDeal from './pages/deals/FeaturedDeal';
import CustomerList from './pages/customer/CustomerList';
import SellerList from './pages/sellers/SellerList';
import ShippingCharges from './pages/shipping/ShippingCharges';
import UpdateCategorey from './pages/UpdateCategory/UpdateCategory';
import UpdateSubCate from './pages/UpdateCategory/UpdateSubCate';
import UpdateSubSubCategory from './pages/UpdateCategory/UpdateSubSubCate'
import UpdateCoupon from './pages/CouponUpdate/CouponUpdate';
import UpdateSubscription from './pages/SubscriptionUpdate/UpdateSubscription';
import Bought_voucher from './pages/deals/BoughtVouchers';
import Bought_subscription from './pages/deals/BoughtSubscriptions';
import BussinessApprovals from './pages/banners/BussinessApprovals';




function  App() {
  return (
    <div className="App">
      <HashRouter>
      <Routes >
        {/* <Route path='/' element={<Login/>} /> */}
        <Route element={<OutRoutes/>}>
          <Route path='/' element={<Dashboard/>}/>




          <Route path='/sub_advertisement' element={<NewProducts/>}/>
          <Route path='/approvedSellerProduct' element={<ApprovedProducts/>}/>
          <Route path='/advertisement_approval' element={<DeniedProducts/>}/>


          <Route path='/category' element={<Category/>}/>
          <Route path='/subCategory' element={<SubCategory/>}/>
          <Route path='/subSubCategory' element={<SubSubCategory/>}/>
          <Route path='/UpdateCategorey/:id' element={<UpdateCategorey/>}/>
          <Route path='/UpdateSubCate/:id' element={<UpdateSubCate/>}/>
          <Route path='/UpdateSubSubCategorey/:id' element={<UpdateSubSubCategory/>}/>
          <Route path='/updateCoupon/:id' element={<UpdateCoupon/>}/>
          <Route path='/advertisement' element={<Products/>}/>
          



          <Route path='/Bussiness' element={<Banners/>}/>
          <Route path='/bussiness_approvals' element={<BussinessApprovals/>} />



          <Route path='/Coupons' element={<FlashDeal/>}/>
          <Route path='bought_Coupons' element={<Bought_voucher/>}/>
          <Route path='/featuredDeal' element={<FeaturedDeal/>}/>
          <Route path='/dealDay' element={<DealDay/>}/>


          <Route path='/subscription' element={<FeaturedDeal/>}/>
          <Route path='/updatesubscription/:id' element={<UpdateSubscription/>}/>
          <Route path='/bought_subscription' element={<Bought_subscription/>}/>


          <Route path='/sellerList' element={<SellerList/>}/>
          


          <Route path='/shippingCharges' element={<ShippingCharges/>}/>

          


        </Route>
      </Routes>
      
      </HashRouter>
    </div>
  );
}

export default App;
